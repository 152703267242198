import { 
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS
} from '../../constants/userConstants'

// **  Initial State
const initialState = {
  userData: {}
}




const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.payload,
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }

    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }
    default:
      return state
  }
}

export default authReducer
