import {
    PATIENT_CREATE_REQUEST,
    PATIENT_CREATE_SUCCESS,
    PATIENT_CREATE_FAIL,

    PATIENT_LIST_REQUEST,
    PATIENT_LIST_SUCCESS,
    PATIENT_LIST_FAIL,

    SCHEDULE_PATIENT_CREATE_REQUEST,
    SCHEDULE_PATIENT_CREATE_SUCCESS,
    SCHEDULE_PATIENT_CREATE_FAIL,

    SEARCH_ID_PATIENT_REQUEST,
    SEARCH_ID_PATIENT_SUCCESS,
    SEARCH_ID_PATIENT_FAIL,

    PATIENT_DETAILS_REQUEST,
    PATIENT_DETAILS_SUCCESS,
    PATIENT_DETAILS_FAIL,

    PATIENT_UPDATE_REQUEST,
    PATIENT_UPDATE_SUCCESS,
    PATIENT_UPDATE_FAIL,
    PATIENT_UPDATE_RESET,

    SEARCH_PATIENT_REQUEST,
    SEARCH_PATIENT_SUCCESS,
    SEARCH_PATIENT_FAIL
} from '../constants/patientConstants'

export const patientListReducers = (state = { patientList: [] }, action) => {
    switch (action.type) {
        case PATIENT_LIST_REQUEST:
            return { ...state, loading: true, patientList: [] }

        case PATIENT_LIST_SUCCESS:
            return { ...state, loading: false, success: true, patientList: action.payload }

        case PATIENT_CREATE_SUCCESS:
            return { ...state, patientList: { data: [action.payload, ...state.patientList.data] } }

        case SEARCH_PATIENT_SUCCESS:
            return { ...state, patientList: action.payload }

        case PATIENT_LIST_FAIL:
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

export const patientCreateReducers = (state = { patientCreate: [] }, action) => {
    switch (action.type) {
        case PATIENT_CREATE_REQUEST:
            return { loading: true }

        case PATIENT_CREATE_SUCCESS:
            return { loading: false, success: true, patientCreate: action.payload }

        case PATIENT_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const patientSearchReducers = (state = { patientSearch: [] }, action) => {
    switch (action.type) {
        case SEARCH_PATIENT_REQUEST:
            return { loading: true }

        case SEARCH_PATIENT_SUCCESS:
            return { loading: false, success: true, patientSearch: action.payload }

        case SEARCH_PATIENT_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const updatePatientReducers = (state = { updatePatientMessage: [] }, action) => {
    switch (action.type) {
        case PATIENT_UPDATE_REQUEST:
            return { loading: true, success: false }

        case PATIENT_UPDATE_SUCCESS:
            return { loading: false, success: action.success, updatePatientMessage: action.payload }

        case PATIENT_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const getPatientReducers = (state = {}, action) => {
    switch (action.type) {
     

        case PATIENT_DETAILS_SUCCESS:
            return { loading: false, detailsPatient: action.payload }

        case PATIENT_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const searchIdPatientReducers = (state = { getSearchIdPatient: [] }, action) => {
    switch (action.type) {
        case SEARCH_ID_PATIENT_REQUEST:
            return { loading: true, success: false }

        case SEARCH_ID_PATIENT_SUCCESS:
            return { loading: false, success: true, getSearchIdPatient: action.payload }

        case SEARCH_ID_PATIENT_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const schedulePatientCreateReducers = (state = { schedulePatientCreate: [] }, action) => {
    switch (action.type) {
        case SCHEDULE_PATIENT_CREATE_REQUEST:
            return { loading: true, ...state }

        case SCHEDULE_PATIENT_CREATE_SUCCESS:
            return { loading: false, success: true, schedulePatientCreate: action.payload }

        case SCHEDULE_PATIENT_CREATE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}