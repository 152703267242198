import { MED_LIST_MEDICINA,MED_LIST_SUCCESS, MED_LIST_FAIL, EX_LIST_EXAMENES, EX_LIST_SUCCESS, EX_LIST_FAIL 
} from '../constants/medicamentosConstants';

export const medicListReducers = (state = { medList: [] }, action) => {
    switch (action.type) {
        case MED_LIST_MEDICINA:
            return { ...state, loading: true, medList: [] }

        case MED_LIST_SUCCESS:
            return { ...state, loading: false, success: true, medList: action.payload }
        
        case MED_LIST_FAIL:
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

export const diagnosisListReducers = (state = {  diagnosis: [] }, action) => {
    switch (action.type) {
        case 'DIAGNOSIS_LIST_MEDICINA':
            return { ...state, loading: true,  diagnosis: [] }

        case 'DIAGNOSIS_LIST_SUCCESS':
            return { ...state, loading: false, success: true,  diagnosis: action.payload }
        
        case 'DIAGNOSIS_LIST_FAIL':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

export const diseaseListReducers = (state = {  disease: [] }, action) => {
    switch (action.type) {
        case 'DISEASE_LIST_MEDICINA':
            return { ...state, loading: true,  disease: [] }

        case 'DISEASE_LIST_SUCCESS':
            return { ...state, loading: false, success: true,  disease: action.payload }
        
        case 'DISEASE_LIST_FAIL':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

export const examenesListReducers = (state = {  exams: [] }, action) => {
    switch (action.type) {
        case 'EX_LIST_EXAMENES':
            return { ...state, loading: true,  exams: [] }

        case 'EX_LIST_SUCCESSS':
            return { ...state, loading: false, success: true,  exams: action.payload }
        
        case 'EX_LIST_FAIL':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

export const medicineListReducers = (state = {  medicine: [] }, action) => {
    switch (action.type) {
        case 'MEDICINE_LIST_MEDICINA':
            return { ...state, loading: true,  medicine: [] }

        case 'MEDICINE_LIST_SUCCESS':
            return { ...state, loading: false, success: true,  medicine: action.payload }
        
        case 'MEDICINE_LIST_FAIL':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

export const consultationCreateReducers = (state = {  consultation: [] }, action) => {
    switch (action.type) {
        case 'CONSULTATION_CREATE_MEDICINA':
            return { ...state, loading: true,  consultation: [] }

        case 'CONSULTATION_CREATE_SUCCESS':
            return { ...state, loading: false, success: true,  consultation: action.payload }
        
        case 'CONSULTATION_CREATE_FAIL':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

