export const PROVINCE_LIST_REQUEST = 'PROVINCE_LIST_REQUEST'
export const PROVINCE_LIST_SUCCESS = 'PROVINCE_LIST_SUCCESS'
export const PROVINCE_LIST_FAIL = 'PROVINCE_LIST_FAIL'

export const CANTON_LIST_REQUEST = 'CANTON_LIST_REQUEST'
export const CANTON_LIST_SUCCESS = 'CANTON_LIST_SUCCESS'
export const CANTON_LIST_FAIL = 'CANTON_LIST_FAIL'

export const PARISH_LIST_REQUEST = 'PARISH_LIST_REQUEST'
export const PARISH_LIST_SUCCESS = 'PARISH_LIST_SUCCESS'
export const PARISH_LIST_FAIL = 'PARISH_LIST_FAIL'

export const PATIENTDATA_LIST_REQUEST = 'PATIENTDATA_LIST_REQUEST'
export const PATIENTDATA_LIST_SUCCESS = 'PATIENTDATA_LIST_SUCCESS'
export const PATIENTDATA_LIST_FAIL = 'PATIENTDATA_LIST_FAIL'