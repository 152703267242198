import {
    PROVINCE_LIST_REQUEST,
    PROVINCE_LIST_SUCCESS,
    PROVINCE_LIST_FAIL,

    CANTON_LIST_REQUEST,
    CANTON_LIST_SUCCESS,
    CANTON_LIST_FAIL,

    PARISH_LIST_REQUEST,
    PARISH_LIST_SUCCESS,
    PARISH_LIST_FAIL,

    PATIENTDATA_LIST_REQUEST,
    PATIENTDATA_LIST_SUCCESS,
    PATIENTDATA_LIST_FAIL,
} from '../constants/nestedSelectConstants'


export const provinceReducers = (state = { province: [] }, action) => {
    switch (action.type) {
        case PROVINCE_LIST_REQUEST:
            return { loading: true, province: [] }

        case PROVINCE_LIST_SUCCESS:
            return { loading: false, success: true, province: action.payload }

        case PROVINCE_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const cantonReducers = (state = { canton: [] }, action) => {
    switch (action.type) {
        case CANTON_LIST_REQUEST:
            return { loading: true, canton: [] }

        case CANTON_LIST_SUCCESS:
            return { loading: false, success: true, canton: action.payload }

        case CANTON_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const parishReducers = (state = { parish: [] }, action) => {
    switch (action.type) {
        case PARISH_LIST_REQUEST:
            return { loading: true, parish: [] }

        case PARISH_LIST_SUCCESS:
            return { loading: false, success: true, parish: action.payload }

        case PARISH_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const patientDataReducers = (state = { patientData: {pais:[]} }, action) => {
    switch (action.type) {
        case PATIENTDATA_LIST_REQUEST:
            return { loading: true }

        case PATIENTDATA_LIST_SUCCESS:
            return { loading: false, success: true, patientData: action.payload }

        case PATIENTDATA_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
