import {
    SCHEDULE_LIST_REQUEST,
    SCHEDULE_LIST_SUCCESS,
    SCHEDULE_LIST_FAIL,
} from '../constants/scheduleConstants'

export const listScheduleReducers = (state = { scheduleData: [] }, action) => {
    switch (action.type) {
        case SCHEDULE_LIST_REQUEST:
            return { loading: true, scheduleData: [] }

        case SCHEDULE_LIST_SUCCESS:
            return {  loading: false, success: true, scheduleData: action.payload }

        case SCHEDULE_LIST_FAIL:
            return {  loading: false, error: action.payload }
        default:
            return state
    }
}


export const listScheduleByDataReducers = (state = { schedule: [] }, action) => {
    switch (action.type) {
        case 'SCHEDULEBYDATA_LIST_REQUEST':
            return { loading: true }

        case 'SCHEDULEBYDATA_LIST_SUCCESS':
            return {  loading: false, success: true, schedule: action.payload }

        case 'SCHEDULEBYDATA_LIST_FAIL':
            return {  loading: false, error: action.payload }
        default:
            return state
    }
}