


export const detailsConsultationReducers = (state = { consultation: [] }, action) => {
    switch (action.type) {
        case 'CONSULTATION_DETAILS_REQUEST':
            return { ...state, loading: true, consultation: [] }

        case 'CONSULTATION_DETAILS_SUCCESS':
            return { ...state, loading: false, success: true, consultation: action.payload }
        
        case 'CONSULTATION_DETAILS_FAIL':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

export const detailsPodologiaReducers = (state = { procedimiento: [] }, action) => {
    switch (action.type) {
        case 'PROC_PODOLOGIA_DETAILS_REQUEST':
            return { ...state, loading: true, procedimiento: [] }

        case 'PROC_PODOLOGIA_DETAILS_SUCCESS':
            return { ...state, loading: false, exito: true, procedimiento: action.payload }
        
        case 'PROC_PODOLOGIA_DETAILS_FAIL':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

export const detaillePodologiaByIdReducers = (state = { datosPodologia: [] }, action) => {
    switch (action.type) {
        case 'PROC_ID_PODOLOGIA_DETAILS_REQUEST':
            return { ...state, loading: true, datos: [] }

        case 'PROC_ID_PODOLOGIA_DETAILS_SUCCESS':
            return { ...state, loading: false, exito: true, datosPodologia: action.payload }
        
        case 'PROC_ID_PODOLOGIA_DETAILS_FAIL':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

export const detailsClinicalConsultationReducers = (state = { clinical: [] }, action) => {
    switch (action.type) {
        case 'CLINICALCONSULTATION_DETAILS_REQUEST':
            return { ...state, loading: true, clinical: [] }

        case 'CLINICALCONSULTATION_DETAILS_SUCCESS':
            return { ...state, loading: false, success: true, clinical: action.payload }
        
        case 'CLINICALCONSULTATION_DETAILS_FAIL':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}

export const detailsPatientHistoryReducers = (state = { clinical: [] }, action) => {
    switch (action.type) {
        case 'PATIENTHISTORY_DETAILS_REQUEST':
            return { ...state, loading: true, clinical: [] }

        case 'PATIENTHISTORY_DETAILS_SUCCESS':
            return { ...state, loading: false, success: true, clinical: action.payload }
        
        case 'PATIENTHISTORY_DETAILS_FAIL':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}




