export const PATIENT_CREATE_REQUEST = 'PATIENT_CREATE_REQUEST'
export const PATIENT_CREATE_SUCCESS = 'PATIENT_CREATE_SUCCESS'
export const PATIENT_CREATE_FAIL = 'PATIENT_CREATE_FAIL'
export const PATIENT_CREATE_RESET = 'PATIENT_CREATE_RESET'

export const PATIENT_LIST_REQUEST = 'PATIENT_LIST_REQUEST'
export const PATIENT_LIST_SUCCESS = 'PATIENT_LIST_SUCCESS'
export const PATIENT_LIST_FAIL = 'PATIENT_LIST_FAIL'

export const SCHEDULE_PATIENT_CREATE_REQUEST = 'SCHEDULE_PATIENT_CREATE_REQUEST'
export const SCHEDULE_PATIENT_CREATE_SUCCESS = 'SCHEDULE_PATIENT_CREATE_SUCCESS'
export const SCHEDULE_PATIENT_CREATE_FAIL = 'SCHEDULE_PATIENT_CREATE_FAIL'

export const SEARCH_ID_PATIENT_REQUEST = 'SEARCH_ID_PATIENT_REQUEST'
export const SEARCH_ID_PATIENT_SUCCESS = 'SEARCH_ID_PATIENT_SUCCESS'
export const SEARCH_ID_PATIENT_FAIL = 'SEARCH_ID_PATIENT_FAIL'


export const DELETE_PATIENT_REQUEST = 'DELETE_PATIENT_REQUEST'
export const DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS'
export const DELETE_PATIENT_FAIL = 'DELETE_PATIENT_FAIL'

export const PATIENT_DETAILS_REQUEST = 'PATIENT_DETAILS_REQUEST'
export const PATIENT_DETAILS_SUCCESS = 'PATIENT_DETAILS_SUCCESS'
export const PATIENT_DETAILS_FAIL = 'PATIENT_DETAILS_FAIL'
export const PATIENT_DETAILS_RESET = 'PATIENT_DETAILS_RESET'

export const PATIENT_UPDATE_REQUEST = 'PATIENT_UPDATE_REQUEST'
export const PATIENT_UPDATE_SUCCESS = 'PATIENT_UPDATE_SUCCESS'
export const PATIENT_UPDATE_FAIL = 'PATIENT_UPDATE_FAIL'
export const PATIENT_UPDATE_RESET = 'PATIENT_UPDATE_RESET'

export const SEARCH_PATIENT_REQUEST = 'SEARCH_PATIENT_REQUEST'
export const SEARCH_PATIENT_SUCCESS = 'SEARCH_PATIENT_SUCCESS'
export const SEARCH_PATIENT_FAIL = 'SEARCH_PATIENT_FAIL'

