// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from '@src/views/apps/user/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import {
  searchIdPatientReducers,
  patientListReducers,
  patientCreateReducers,
  schedulePatientCreateReducers,
  getPatientReducers,
  updatePatientReducers,

} from '../reducers/patientReducers'
import {
  provinceReducers,
  cantonReducers,
  parishReducers,
  patientDataReducers,
  
  
} from '../reducers/nestedSelectReducers'
import {
  listScheduleReducers,
  listScheduleByDataReducers,
}from '../reducers/scheduleReducers'

import {
  medicListReducers,
  diagnosisListReducers,
  diseaseListReducers,
  medicineListReducers,
  examenesListReducers,
  consultationCreateReducers,
} from '../reducers/medicamentoReducers'

import {
  detailsConsultationReducers,
  detailsPodologiaReducers,
  detaillePodologiaByIdReducers,
  detailsClinicalConsultationReducers,
  detailsPatientHistoryReducers
} from './consultationReducers.js'
const rootReducer = combineReducers({
  auth,
  listScheduleReducers,
  medicListReducers,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  provinceReducers,
  cantonReducers,
  parishReducers,
  patientListReducers,  
  patientDataReducers,
  patientCreateReducers,
  schedulePatientCreateReducers,
  searchIdPatientReducers,
  getPatientReducers,
  updatePatientReducers,
  listScheduleByDataReducers,
  detailsConsultationReducers,
  detailsPodologiaReducers,
  detaillePodologiaByIdReducers,
  diagnosisListReducers,
  diseaseListReducers,
  medicineListReducers,
  examenesListReducers,
  consultationCreateReducers,
  detailsClinicalConsultationReducers,
  detailsPatientHistoryReducers,
})

export default rootReducer
